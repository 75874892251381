import { merge, isObject, cloneDeep } from 'lodash-es';
import PapaCSV                        from 'papaparse';
import { v4 as UUID }                 from 'uuid';

export default {

    computed: {

        bvDataTablePaginationInfo () {

            const start = this.bvDataTable.offset + 1;
            const remaining = this.bvDataTable.totalRecords - start;

            return `Showing ${ this.bvDataTable.offset + 1 } to ${ this.bvDataTable.offset + 1 + Math.min (
                remaining,
                this.bvDataTable.rowsPerPage
            ) } of ${ this.bvDataTable.totalRecords }`;

        }

    },

    methods: {

        // To be overridden
        bvDataTableFormatRow: row => row,

        // Fetch handler for the data table
        async bvDataTableFetch ( context ) {

            this.bvDataTable.currentPage = context.currentPage;
            this.bvDataTable.rowsPerPage = context.perPage;

            this.bvDataTable.fetchingData = true;

            // Fetch page from server
            try {

                this.bvDataTable.offset = ( this.bvDataTable.currentPage * this.bvDataTable.rowsPerPage ) - this.bvDataTable.rowsPerPage;

                const params = [
                    `offset=${ this.bvDataTable.offset }`,
                    `limit=${ this.bvDataTable.rowsPerPage }`
                ];
                if ( context.filter ) {
                    params.push ( `q=${ context.filter }` );
                }

                if ( ( context.sortBy || '' ).length > 0 ) {
                    params.push ( `orderBy[]=${ context.sortBy }` );
                    params.push ( `orderDir[]=${ context.sortDesc ? 'desc' : 'asc' }` );
                }

                const { data } = await this.$http.get (
                    `${ this.config.apiRoot }/${ this.bvDataTable.collectionName }?${ params.join ( '&' ) }`,
                    {
                        responseType   : 'json',
                        withCredentials: false // CORS dev server
                    }
                );

                this.bvDataTable.totalRecords = data.total;
                this.bvDataTable.records = data.records;
                this.bvDataTable.rows = cloneDeep ( this.bvDataTable.records )
                    .map ( x => this.bvDataTableFormatRow ( x ) )
                ;
                this.bvDataTable.fetchingData = false;
                return this.bvDataTable.rows;

            } catch ( e ) {

                // Bubble the exception
                this.error = this.$utils.normaliseError ( e );
                this.$utils.logger ( 'error', '[USERS] Error fetching data:', e );
                this.bvDataTable.fetchingData = false;
                return [];

            }

        },

        // Merge in datatable options with defaults
        bvDataTableGetDataDefaults ( data ) {
            return merge (
                {},
                {
                    id                : UUID (),
                    fetchingData      : false,
                    offset            : 0,
                    rowsPerPage       : 25,
                    currentPage       : 1,
                    totalRecords      : 0,
                    records           : [],
                    rows              : [],
                    textFilter        : null,
                    rowsPerPageOptions: [ 5, 10, 25, 50, 100 ],
                    currentRecord     : null
                },
                data
            );
        },

        // Download all data
        async bvDataTableDownloadAll ( format ) {

            this.bvDataTable.fetchingData = true;

            try {

                const { data } = await this.$http.get (
                    `${ this.config.apiRoot }/${ this.bvDataTable.collectionName }?offset=0&limit=0`,
                    {
                        responseType   : 'json',
                        withCredentials: false // CORS dev server
                    }
                );

                switch ( format ) {

                    case 'csv':

                        const buffer = PapaCSV.unparse (
                            data.records.map ( x => {

                                for ( const [ key, value ] of Object.entries ( x ) ) {

                                    if ( isObject ( value ) ) {

                                        const pairs = [];
                                        for ( const [ subKey, subValue ] of Object.entries ( value ) ) {
                                            pairs.push ( `${ subKey }=${ subValue }` );
                                        }
                                        x[ key ] = pairs.join ( '|' );

                                    }

                                }

                                return x;

                            } ),
                            {
                                quotes    : true,
                                quoteChar : '"',
                                escapeChar: '"',
                                delimiter : ','
                            }
                        );

                        this.$utils.downloadDataFile (
                            buffer,
                            'text/csv',
                            `${ this.bvDataTable.collectionName }.${ ( new Date () ).toISOString () }.csv`
                        );

                        break;

                    default:
                        this.$utils.downloadJSON (
                            data.records,
                            `${ this.bvDataTable.collectionName }.${ ( new Date () ).toISOString () }.json`
                        );

                }

                this.bvDataTable.fetchingData = false;

            } catch ( e ) {

                // Bubble the exception
                this.error = this.$utils.normaliseError ( e );
                this.$utils.logger ( 'error', '[USERS] Error fetching data:', e );
                this.bvDataTable.fetchingData = false;

            }

        },

        // Row selected event
        bvDataTableSelectRow ( rows ) {

            // De-selected?
            if ( rows.length === 0 ) {
                this.bvDataTable.currentRecord = null;
                return;
            }

            // First clear the current record, to cause the form to re-render
            this.bvDataTable.currentRecord = null;

            // Then init it with the current record
            this.$nextTick ( () => {
                this.bvDataTable.currentRecord = cloneDeep ( this.bvDataTable.records.find ( x => x.id === rows[ 0 ].id ) );
            } );

        },

        // Restore current record
        bvDataTableRestoreCurrentRecord () {

            if ( this.bvDataTable.currentRecord ) {

                // First clear the current record, to cause the form to re-render
                const record = this.bvDataTable.records.find ( x => {
                    return x.id === this.bvDataTable.currentRecord.id;
                } );
                this.bvDataTable.currentRecord = null;

                // Then init it with the original record
                this.$nextTick ( () => {
                    this.bvDataTable.currentRecord = cloneDeep ( record );
                } );

            }
        },

        // Handler for client-side changed record
        bvDataTableUpdateCurrentRecord () {

            // Update current record - for server-side changes
            this.bvDataTable.currentRecord = null;

            // Trigger table refresh
            this.$root.$emit ( 'bv::refresh::table', this.bvDataTable.id );

        },

        // Handler for client-side deleted record
        bvDataTableDeleteCurrentRecord () {

            // Update current record - for server-side changes
            this.bvDataTable.currentRecord = null;

            // Trigger table refresh
            this.$root.$emit ( 'bv::refresh::table', this.bvDataTable.id );

        }

    }

};