<template>
	<b-overlay :show="loading" variant="light" spinner-type="grow">
		<b-form @submit.prevent="saveRecord" class="text-left m-2">

			<b-alert :show="!!error" variant="danger" v-html="error.message"></b-alert>

			<b-card no-body class="mb-2">
				<b-tabs card>

					<b-tab title="Payment" active>

						<b-form-group
								label="Payment date:"
								label-for="paymentDate"
								:invalid-feedback="bvDataForm.validationErrors.paymentDate"
								:state="!bvDataForm.validationErrors.paymentDate"
								class="mb-1 p-2 shadow-sm"
						>
							<b-form-datepicker
									id="paymentDate"
									v-model="record.paymentDate"
							></b-form-datepicker>
						</b-form-group>

						<b-form-group
								label="Amount:"
								label-for="amount"
								class="mb-1 p-2 shadow-sm"
						>
							<b-form-input
									id="amount"
									v-model.number="record.amount"
									type="number"
									step="0.01"
									required
							></b-form-input>
						</b-form-group>

						<b-form-group
								label="Student:"
								label-for="studentId"
								class="mb-1 p-2 shadow-sm"
								:invalid-feedback="bvDataForm.validationErrors.studentId"
								:state="!bvDataForm.validationErrors.studentId"
						>
							<b-form-select
									id="studentId"
									v-model="record.studentId"
									:options="students"
							></b-form-select>
						</b-form-group>

						<b-form-group
								label="Payment Method:"
								label-for="paymentMethodId"
								class="mb-1 p-2 shadow-sm"
								:invalid-feedback="bvDataForm.validationErrors.paymentMethodId"
								:state="!bvDataForm.validationErrors.paymentMethodId"
						>
							<b-form-select
									id="paymentMethodId"
									v-model="record.paymentMethodId"
									:options="paymentMethods"
							></b-form-select>
						</b-form-group>

						<b-form-group
								label="Transaction Type:"
								label-for="transactionTypeId"
								class="mb-1 p-2 shadow-sm"
								:invalid-feedback="bvDataForm.validationErrors.transactionTypeId"
								:state="!bvDataForm.validationErrors.transactionTypeId"
						>
							<b-form-select
									id="transactionTypeId"
									v-model="record.transactionTypeId"
									:options="transactionTypes"
							></b-form-select>
						</b-form-group>

					</b-tab>

					<b-tab title="Notes">
						<table class="table mt-4 table-hover">
							<tbody v-if="!record.notes || record.notes.length === 0">
								<tr>
									<td colspan="2"><em>No payment notes</em></td>
								</tr>
							</tbody>
							<tbody v-else>
								<tr
										v-for="(note, idx) of record.notes"
										:key="idx"
								>
									<td>{{ note }}</td>
									<td class="text-center">
										<b-link @click="deleteNote(idx)">
											<b-icon icon="trash" variant="danger"></b-icon>
										</b-link>
									</td>
								</tr>
							</tbody>
							<tfoot>
								<tr>
									<td colspan="2" class="p-0">
										<b-form-group
												label="Add new note:"
												label-for="paymentNewNote"
												class="mt-4 mx-0 p-2 shadow-sm"
										>
											<b-input-group>
												<b-form-input id="paymentNewNote" v-model="newNote"></b-form-input>
												<b-input-group-append>
													<b-button variant="outline-info" @click="addNewNote"
													          class="shadow-none">
														<b-icon icon="plus-circle-fill" variant="primary"></b-icon>
													</b-button>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									</td>
								</tr>
							</tfoot>
						</table>
					</b-tab>

				</b-tabs>
			</b-card>

			<div class="d-flex justify-content-end">
				<b-button v-if="!isNew" type="button" class="text-danger" variant="link"
				          @click="deleteRecord">
					Delete
				</b-button>
				<b-button type="button" :disabled="!isNew && !bvDataForm.dirty" class="ml-2 mr-0" variant="secondary"
				          @click="bvDataFormReset">
					{{ isNew ? 'Cancel' : 'Reset' }}
				</b-button>
				<b-button type="submit" :disabled="!bvDataForm.dirty" class="ml-2 mr-0" variant="primary">
					{{ isNew ? 'Create' : 'Save Changes' }}
				</b-button>
			</div>

		</b-form>
	</b-overlay>
</template>

<script>

import { cloneDeep, omit } from 'lodash-es';
import bvDataFormMixin     from '@/mixins/bvDataForm.mixin';

export default {

    name      : 'DataFormPayment',
    mixins    : [ bvDataFormMixin ],
    components: {},

    props: {

        record: {
            type: Object
        },

        transactionTypes: {
            type    : Array,
            required: true
        },

        paymentMethods: {
            type    : Array,
            required: true
        },

        students: {
            type    : Array,
            required: true
        }

    },

    computed: {
        isNew () {
            return this.record && !this.record.id;
        }
    },

    data () {
        return {
            bvDataForm: this.bvDataFormGetDataDefaults (
                {
                    id          : 'paymentsForm',
                    resourceName: 'payment'
                }
            ),
            loading   : true,
            error     : false,
            newNote   : ''
        };
    },

    watch: {},

    methods: {

        async saveRecord () {
            try {

                this.loading = true;
                this.error = false;

                const saveData = cloneDeep ( omit ( this.record, this.$utils.auditFields ) );

                // Save changes
                await this.bvDataFormSave ( saveData );
                this.$utils.logger ( 'info', '[AUTH] Changes saved to payment.' );
                this.$bvToast.toast (
                    'Changes for payment were saved successfully.',
                    {
                        title        : 'Saved',
                        autoHideDelay: 3000,
                        toaster      : 'b-toaster-top-center',
                        solid        : true
                    }
                );

                this.loading = false;
                return true;

            } catch ( e ) {
                this.error = {
                    message: 'The form has errors, please review and try again.',
                    errors : e.errors
                };
                this.bvDataForm.validationErrors = this.$utils.parseFormValidationErrors ( this.error.errors );
                this.loading = false;
                return false;
            }
        },

        async deleteRecord () {
            try {

                this.loading = true;
                this.error = false;

                // Delete record
                const response = await this.bvDataFormDelete ();
                if ( response !== false ) {

                    this.$utils.logger ( 'info', '[AUTH] Payment deleted.', response.data );
                    this.$bvToast.toast (
                        'Payment deleted successfully.',
                        {
                            title        : 'Deleted',
                            autoHideDelay: 3000,
                            toaster      : 'b-toaster-top-center',
                            solid        : true
                        }
                    );

                }

                this.loading = false;
                return true;

            } catch ( e ) {
                this.error = {
                    message: 'The form has errors, please review and try again.',
                    errors : e.errors
                };
                this.bvDataForm.validationErrors = this.$utils.parseFormValidationErrors ( this.error.errors );
                this.loading = false;
                return false;
            }
        },

        deleteNote ( noteIdx ) {
            this.record.notes.splice ( noteIdx, 1 );
        },

        addNewNote () {
            if ( this.newNote.length > 0 ) {
                if ( !Array.isArray ( this.record.notes ) ) {
                    this.$set ( this.record, 'notes', [] );
                }
                this.record.notes.push ( this.newNote );
                this.newNote = '';
            }
        }

    },

    mounted () {

        // Ready
        this.loading = false;

    }

};

</script>

<style scoped lang="scss">
.side-by-side-2 {
	display: inline-block;
	width: calc(50% - 5px);
	margin-right: 5px;
}
</style>