import { merge }      from 'lodash-es';
import { v4 as UUID } from 'uuid';

export default {

    watch: {
        record: {
            handler: function () {
                this.bvDataForm.dirty = true;
            },
            deep   : true
        }
    },

    computed: {},

    methods: {

        // Merge in datatable options with defaults
        bvDataFormGetDataDefaults ( data ) {
            return merge (
                {},
                {
                    id              : UUID (),
                    dirty           : false,
                    validationErrors: {}
                },
                data
            );
        },

        // Form reset
        bvDataFormReset () {
            this.bvDataForm.dirty = false;
            this.$emit ( 'reset' );
        },

        // Form save
        async bvDataFormSave ( saveData ) {

            const response = await this.$store.dispatch ( `${ this.bvDataForm.resourceName }/save`, saveData );

            this.bvDataForm.dirty = false;
            this.$emit ( 'changed', response.data );

        },

        async bvDataFormDelete () {

            const result = await this.$confirm (
                {
                    title: 'Delete record?',
                    html : 'Are you sure you want to delete this record?'
                }
            );
            if ( result.isConfirmed ) {

                const response = await this.$store.dispatch (
                    `${ this.bvDataForm.resourceName }/delete`,
                    this.record.id
                );

                this.$emit ( 'deleted', this.record.id );

                return response;

            }

            return false;

        }

    }

};